import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DynamoDB from '@/models/DynamoDB'

(async () => {
	Vue.config.ignoredElements = await DynamoDB.getCustomTags()
	Vue.config.productionTip = false

	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app')
})()
