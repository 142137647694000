/**
*
* This is the interface of our REST API.
*/
import Axios from './Axios'

export default {
    post(path, data, params) {
        return Axios.post(path, data, {params});
    },

    get(path, params) {
        return Axios.get(path, {params});
    },

    put(path, data, params) {
        return Axios.put(path, data, {params});
    },

    delete(path, data, params) {
        return Axios.delete(path, {data, params});
    }
}