<template>
  <div class="steps" :class="[{active: getActiveStep === id}]">
    <div class="steps-float-container" :style="[{top: top + 'px'}]">
      <div :id="'steps' + id" class="steps-container">
        <div class="top-bar">
          <SLButton type="close" @click.native="closeStep()"/>
        </div>
        <div class="content">
          <ul>
            <template v-for="(step, index) in steps">
              <li v-if="index <= stepCount" :class="[{show: index <= showTitle }]" :key="'steps-' + id + '-' + index">
                <h5 class="title"><span v-html="step.title"></span></h5>
                <p class="desc" :class="[{show: index <= showContent, 'no-delay': noDelayContent}]" v-html="step.content"></p>
              </li>
            </template>
          </ul>
        </div>
        <div class="buttom-bar">
          <SLButton v-if="stepCount < steps.length - 1" :addedClass="showButton ? 'show': 'hide'" :hasDelay="true" type="add" @click.native="addStepContent($event)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SLButton from '@/components/SLButton'

export default {
  props: {
    id: {
      type: Number,
      default: null
    },

    section: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      top: 0,
      stepCount: 0,
      showContent: -1,
      showTitle: 0,
      activeButton: false,
      showButton: false,
      noDelayContent: false
    }
  },

  components: { SLButton },

  computed: {
    ...mapGetters(['getActiveStep', 'getWidgetData']),

    steps() { return this.section.steps },

    showPopup() { return this.section.showPopup },

    animationDelay() { return this.getWidgetData.theme.animationDelay },

    lastItem() { return this.stepCount === this.steps.length - 1 }
  },

  watch: {
    getActiveStep(data, old) {
      if (data === this.id) {
        this.stepCount = 0;
        this.showTitle = 0;
        this.showContent = -1;
        this.showButton = false;
        this.activeButton = false;
        this.noDelayContent = false;
        this.clearYellowText();
        this.prepareYellowTextDelay();
      }
    }
  },

  mounted() { this.setPopUp() },

  methods: {
    setPopUp() {
      try {
        if (this.showPopup === 'title') 
          this.top = document.querySelector('#section' + this.id + ' .title').offsetTop;
        else if (this.showPopup === 'content') 
          this.top = document.querySelector('#section' + this.id + ' .content').offsetTop;
      } catch (error) {
        this.top = 0;
      }
    },

    closeStep() {
      document.querySelector('#steps' + this.id).classList.remove('show')
      this.$store.commit('setActiveStep', null);
      this.clearYellowText();
    },

    addStepContent(e) {
      if (!this.activeButton) 
        return true

      this.addTitleDelay();
      this.showButton = false;
      this.activeButton = false;
      
      if (this.stepCount + 1 < this.steps.length) {
        this.stepCount++;
        this.clearYellowText();
        this.prepareYellowTextDelay();
      }
    },

    showYellowText(delay) {
      if (delay)
        this.choiceYellowTag();

      if (this.lastItem && delay) 
        this.noDelayContent = true;
      
      this.showContent++;

      delay = this.animationDelay * 1000;

      setTimeout( _=> {
        this.showButton = true;
      }, delay)

      setTimeout( _=> {
        this.activeButton = true;
      }, (delay) + 300)
    },

    choiceYellowTag() {
      let tag = this.steps[this.stepCount];

      if (tag.hasOwnProperty('tag')) 
        this.ativateYellowText(tag.tag);
      else{
        if (this.section.sectionType === 'image') 
          this.showYellowImage();
        else if (this.lastItem)
          this.showAllYellowText();
      }
    },

    showAllYellowText() {
      for (let i = 0; i < this.steps.length; i++) {
        let tag = this.steps[i];
        if (tag.hasOwnProperty('tag')) 
          this.ativateYellowText(tag.tag);
      }
    },

    ativateYellowText(tag) {
      let yellowText = document.querySelectorAll('#section' + this.id + ' ' + tag)
      
      for (let i = 0; i < yellowText.length; i++) {
        yellowText[i].classList.add('active-yellow');
        yellowText[i].classList.add('show');
      }
    },

    showYellowImage() {
      let image = document.querySelector('#section' + this.id + ' .content-img')
      image.classList.add('active-yellow');
      image.classList.add('show');
    },

    clearYellowText() {
      let yellowText = document.querySelectorAll('.active-yellow');
      
      for (let i = 0; i < yellowText.length; i++) {
        if (yellowText[i].classList.contains('show')) 
          yellowText[i].classList.remove('show')
      }
    },

    prepareYellowTextDelay() {
      let tag = this.steps[this.stepCount];
      let delay = this.animationDelay * 1000;

      if (!tag.hasOwnProperty('tag') && this.section.sectionType != 'image' && !this.lastItem) 
        delay = 0;
        
      if (this.lastItem && !(this.section.sectionType === 'image')) 
        delay = !this.checkTagsAllSteps() ? 0: delay;

      setTimeout( _=> {
        this.showYellowText(delay);
      }, delay)
    },

    checkTagsAllSteps() {
      let retval = false;
      for (let i = 0; i < this.steps.length; i++) {
        let tag = this.steps[i];
        if (tag.hasOwnProperty('tag')) {
          retval = true;
          return true;
        }
      }

      return retval;
    },

    addTitleDelay(index) {
      setTimeout( _=> { this.showTitle++ }, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
$animation-delay: var( --animation-delay );

.steps {
  position: relative;
  line-height: 20px;

  &.active { z-index: 1; }

  .steps-float-container {
    position: absolute;

    .steps-container {
      transition: opacity 0.6s linear;
      border: 1px solid #BABABA;
      border-radius: 6px;
      padding: 20px 13px;
      position: relative;
      opacity: 0;
      
      .content {
        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            opacity: 0;
            margin-bottom: 20px;
            font-size: 16px;
            list-style: decimal;
            list-style-position: inside;
            transition: opacity 0.6s linear;

            &::marker { 
              font-weight: 700;
            }

            &.show { transition: opacity 0.6s linear; }

            .title { 
              width: calc(100% - 20px);
              display: inline-flex;
             }

            .title, .desc {
              margin: 0;
              font-size: inherit;
            }

            .desc {
              opacity: 0;

              &.show { transition: opacity 0.6s linear; }
              &.show { transition-delay: $animation-delay; }

              &.no-delay { transition-delay: 0s; }
            }

            &:last-of-type { margin-bottom: 0px; }
          }
        }
      }
    }

    .show { opacity: 1 !important; }
  }
}
</style>

<style lang="scss">
.steps {
  &.active .button { cursor: pointer; }

  .button { cursor: default; }
}
</style>