/**
*
* This is the DynamoDB model.
*/
import Api from './Api';
import Util from '@/helpers/Util';

const readItem = (data, tableName = process.env.VUE_APP_TABLE_NAME) => {
    const params = Object.assign(data, {tname: tableName});
    return Api.get(process.env.VUE_APP_READ_PATH, params).then(response => response.data.Items).catch(err => {throw Util.getError(err)});
};

const readItemByBatch = (data, tableName = process.env.VUE_APP_TABLE_NAME) => {
	const params = {
		tname: tableName,
		data: Buffer.from(JSON.stringify(data), 'utf8').toString('base64')
	};
	return Api.get(process.env.VUE_APP_READ_BATCH_PATH, params).then(response => response.data.Responses[tableName]).catch(err => {throw Util.getError(err)});
};

export default class DynamoDB {
	constructor(store) {
		this.store = store;
		this.defaultProps = ['PK', 'SK1', 'user', 'status', 'createdDate', 'modifiedDate'];
		this.fileProps = ['video', 'image', 'thumbnail', 'retina'];
		this.sectionProps = ['section'];
		this.themeProps = ['theme'];
		this.assetProps = ['asset', 'playIcon', 'plusIcon', 'closeIcon', 'favicon'];
		this.groupProps = ['sections', 'steps'];
		this.sectionList = [];
		this.themeList = [];
		this.assetList = [];
	}

	static getConfig() {
		return readItem({
			pkname: 'PK',
			pkvalue: 'config'
		})
	}

	static async getCustomTags() {
		let config = await this.getConfig();
		let ret = [];

		if (config.length > 0 && config[0].hasOwnProperty('customTags'))
			ret = config[0].customTags;

		return ret;
	}

	static async getNotFoundMessage() {
		let config = await this.getConfig();
		let ret = undefined;

		if (config.length > 0 && config[0].hasOwnProperty('notFoundMessage'))
			ret = config[0].notFoundMessage;

		return ret;
	}

	fetchItemsByBatch(pk, data, tableName) {
		let distinctData = [...new Set(data)]; // removing duplicates
		let keys = distinctData.map(id => {return {PK: pk, SK1: id}});
		return readItemByBatch(keys, tableName);
	}

	fetchPages(type, url) {
		return readItem({
			pkname: 'widgetType',
			pkvalue: type,
			skname: 'pageUrl',
			skvalue: url,
			idxname: 'widgetType-pageUrl-index'
		});
	}

	async initialize(type = 'sl', url) {
		let pages = await this.fetchPages(type, url);
		this.store.commit('setPages', pages);

		for (let page of pages)
			this.parse(page);

		let values = await Promise.all([this.fetchItemsByBatch('section', this.sectionList), this.fetchItemsByBatch('theme', this.themeList)]);
		let sections = values[0];
		let themes = values[1];

		this.store.commit('setSections', sections);
		this.store.commit('setThemes', themes);

		for (let section of sections)
			this.parse(section);

		for (let theme of themes)
			this.parse(theme);

		let assets = await this.fetchItemsByBatch('asset', this.assetList, process.env.VUE_APP_ASSET_TABLE_NAME);
		this.store.commit('setAssets', assets);
	}

	parse(obj) {
		this.parseGroupData(obj, this.groupProps);
		let sectionIds = this.parseData(obj, this.sectionProps);
		let themeIds = this.parseData(obj,this.themeProps);
		let assetIds = this.parseData(obj, this.assetProps);

		Util.push(this.sectionList, sectionIds);
		Util.push(this.themeList, themeIds);
		Util.push(this.assetList, assetIds);
	}

	parseData(obj, props = []) {
		let ret = [];

		for (let prop of props) {
			if (obj.hasOwnProperty(prop))
				ret.push(obj[prop]);
		}

		return ret.flat();
	}

	parseGroupData(obj, props = []) {
		for (let prop of props) {
			if (obj.hasOwnProperty(prop)) {
				let values = obj[prop];

				for (let value of values)
					this.parse(value);
			}
		}
	}

	getData(url) {
		return this.getPage(url);
	}

	getPage(url) {
		let page = this.store.getters.getPageByUrl(url);

		if (page === undefined)
			throw new Error(`Page not found. URL: ${url}`);

		page = this.transform(Util.clone(page));
		return this.removeProps(page, this.defaultProps);
	}

	transform(obj = {}) {
		obj = this.getTransformedData(obj, this.assetProps, false, false, this.getAsset.bind(this));
		obj = this.getTransformedData(obj, this.themeProps, false, true, this.getTheme.bind(this));
		obj = this.getTransformedData(obj, this.sectionProps, false, true, this.getSection.bind(this));
		obj = this.getTransformedData(obj, this.groupProps, true);

		return obj;
	}

	getTransformedData(obj = {}, props = [], group = false, nested = false, callback) {
		for (let prop of props) {
			if (obj.hasOwnProperty(prop))
				obj[prop] = group ? this.transformGroupData(obj[prop]) : this.transformData(obj[prop], nested, callback);
		}

		return obj;
	}

	transformGroupData(values = []) {
		values.sort(this.compareOrder);

		for (let value of values)
			value = this.transform(value);

		return values;
	}

	transformData(ids = [], nested = false, callback) {
		let obj = this.getDataByIds(ids, callback);

		if (nested)
			obj = this.transform(obj);

		return obj;
	}

	getDataByIds(ids = [], callback) {
		let ret = [];

		for (let id of ids)
			ret.push(callback(id));

		if (ret.length > 1)
			return ret;

		return ret[0] || {};
	}

	getTheme(id) {
		let theme = this.store.getters.getThemeByID(id);

		if (theme === undefined)
			throw new Error(`Theme not found. ID: ${id}`);

		return this.removeProps(Util.clone(theme), this.defaultProps);
	}

	getSection(id) {
		let section = this.store.getters.getSectionByID(id);

		if (section === undefined)
			throw new Error(`Section not found. ID: ${id}`);

		return this.removeProps(Util.clone(section), this.defaultProps);
	}

	getAsset(id) {
		let asset = this.store.getters.getAssetByID(id);

		if (asset === undefined)
			throw new Error(`Asset not found. ID: ${id}`);

		asset = this.transformAsset(Util.clone(asset), this.fileProps);
		return this.removeProps(asset, this.defaultProps.concat(this.fileProps));
	}

	transformAsset(obj = {}, props = []) {
		for (let prop of props) {
			if (obj.hasOwnProperty(prop))
				obj[prop + 'Url'] = process.env.VUE_APP_CLOUDFRONT_URL + process.env.VUE_APP_PUBLIC_PATH + obj[prop][0].storageName;
		}

		return obj;
	}

	removeProps(obj = {}, props = []) {
		for (let prop of props)
			delete obj[prop];

		return obj;
	}

	compareOrder(a, b) {
		return parseInt(a.order) - parseInt(b.order);
	}
}