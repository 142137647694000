<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import Util from '@/helpers/Util';

export default {

  computed: {
    ...mapGetters([
      'getIsIpad',
      'getIsMobile',
      'getWidgetData',
      'getIsIOS'
    ]),

    isDesktop() { return !this.getIsIpad && !this.getIsMobile }
  },

  watch: {
    getIsIpad(data, old) { this.updateImagesToRetina(data); },
    getIsMobile(data, old) { this.updateImagesToRetina(data); },
    isDesktop(data, old) { this.updateImagesToRetina(data); },
    getWidgetData(data, old) { this.updateImagesToRetina(data); }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('load', this.onResize, { passive: true })
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener('load', this.onResize, { passive: true })
    window.addEventListener('resize', this.onResize, { passive: true })
    this.updateImagesToRetina(true);
  },

  methods: {
    onResize() {
      let isMobile = false,
          isIpad = false,
          windowWidth = window.innerWidth;

      if (windowWidth <= 1024 && windowWidth >= 768) {
        isIpad = true;
      } else if (windowWidth < 768) {
        isMobile = true;
      }

      this.$store.commit('setIsMobile', isMobile);
      this.$store.commit('setIsIpad', isIpad);
      this.checkRealDevice();
    },

    updateImagesToRetina(condition) {
      if (condition) {
        setTimeout(_=>{ 
          Util.initRetina();
        }, 1)
      }
    },

    checkRealDevice() {
      let userAgent = window.navigator.userAgent;

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || navigator.platform.indexOf('Mac') > -1) 
        this.$store.commit('setIsIOS', true);
      else
        this.$store.commit('setIsIOS', false);
    }
  }
}
</script>

<style lang="scss">
$d1-text-color: var( --d1-text-color );
$d2-text-color: var( --d2-text-color );
$d3-text-color: var( --d3-text-color );
$animation-delay: var( --animation-delay );
$highlight-text-color: var( --highlight-text-color );

@font-face{
    font-family:"Source Sans Pro";
    src:url("./assets/Fonts/SourceSansPro-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face{
    font-family:"Source Sans Pro";
    src:url("./assets/Fonts/SourceSansPro-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face{
    font-family:"Source Sans Pro";
    src:url("./assets/Fonts/SourceSansPro-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face{
    font-family:"Source Sans Pro";
    src:url("./assets/Fonts/SourceSansPro-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face{
    font-family:"Source Sans Pro";
    src:url("./assets/Fonts/SourceSansPro-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face{
    font-family:"Source Sans Pro";
    src:url("./assets/Fonts/SourceSansPro-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

* { box-sizing: border-box; }

br {
  content: " ";
  display: block;
  margin: 0;
  line-height: 0;
  margin-bottom: 19px;
}

h1, h2, h3, h4, h5, h6 { margin: 0; }

html {
  font-size: 20px;
  font-family:"Source Sans Pro";
  max-width: 815px;

  body { margin: 0; }
}

d1 {
  cursor: pointer;
  color: $d1-text-color; 
}

d2 { color: $d2-text-color; }

d3 { color: $d3-text-color; }

d1, d2, d3 { font-weight: 700; }

.hide-play-cursor d1 { cursor: default; }

.active-yellow {
  position: relative;
  transition: all 0.6s linear;
  box-shadow: -1px 1px 0 0 transparent, -1px -2px 0 0 transparent;

  &.show {
    background-color: $highlight-text-color;
    box-shadow: -1px 1px 0 0 $highlight-text-color, -1px -2px 0 0 $highlight-text-color;
  }
}

.is-ios { 
  .active-yellow {
    box-shadow: -1px -5px 0 0 transparent, -1px 2px 0 0 transparent;

    &.show { box-shadow: -1px -5px 0 0 $highlight-text-color, -1px 2px 0 0 $highlight-text-color }
  }
}

.content-img {
  display: inline-flex;
  position: relative;

  &.active-yellow {
    &.show { .img-border { border-color: $highlight-text-color; } }
  }

  .img-border {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: border 0.6s linear;
    border: 8px solid transparent;
  }
}
</style>
