<template>
  <button 
    class="button" 
    :class="[type, addedClass, {hide: hide}, {'has-delay': hasDelay}]"
    :style="[
      top != null ?
        {top: top + 'px', opacity: 1}
      : ''
    ]"
  >
    <img 
      :src="image" 
      :data-1x="image" 
      :data-2x="image2x"
      :alt="altText" 
      height="25" 
      width="25"
      draggable="false"
    >
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    hide: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'play'
    },
    addedClass: {
      type: String,
      default: null
    },
    hasDelay: {
      type: Boolean,
      default: false
    },
    top: {
      type: Number,
      default: null
    }
  },

  computed: {
    ...mapGetters(['getWidgetData']),

    theme() { return this.getWidgetData.theme },

    image() { return this.getImage() },

    image2x() { return this.getImage('2x') },
    
    altText() {
      if (this.type === 'play') 
        return this.getWidgetData.theme.playIcon.altTextFr
      else if (this.type === 'close') 
        return this.getWidgetData.theme.closeIcon.altTextFr
      else if (this.type === 'add') 
        return this.getWidgetData.theme.plusIcon.altTextFr
    }
  },

  methods: {
    getImage(retina = '1x') { 
      if (this.type === 'play') 
        return retina === '2x' ? this.getWidgetData.theme.playIcon.retinaUrl: this.getWidgetData.theme.playIcon.imageUrl
      else if (this.type === 'close') 
        return retina === '2x' ? this.getWidgetData.theme.closeIcon.retinaUrl: this.getWidgetData.theme.closeIcon.imageUrl
      else if (this.type === 'add') 
        return retina === '2x' ? this.getWidgetData.theme.plusIcon.retinaUrl: this.getWidgetData.theme.plusIcon.imageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
$animation-delay: var( --animation-delay );

.button {
  width: 25px;
  height: 25px;
  border: none;
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  padding: 0;

  &:active, &:focus { outline: none; }

  &.play {
    transition: opacity 0.3s linear;
    left: -40px;
    opacity: 0;
  }

  &.close {
    top: -20px;
    right: -2px;
  }

  &.add {
    bottom: -30px;
    left: -2px;
  }

  &.has-delay.show { transition: opacity 0.6s linear }

  &.hide { 
    opacity: 0 !important; 
    cursor: default !important;
  }

  &.show { opacity: 1 !important; }

  img {
    object-fit: cover;
    object-position: center;
  }
}
</style>