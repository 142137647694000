<template>
  <div class="sections">
    <SLSectionContent :id="index" :section="section.section"/>
    <SLSectionStrategy :id="index" :section="section.section"/>
  </div>
</template>

<script>
import SLSectionContent from '@/components/SLSectionContent'
import SLSectionStrategy from '@/components/SLSectionStrategy'

export default {
  props: {
    index: {
      type: Number,
      default: null
    },

    section: {
      type: Object,
      default: null
    }
  },

  components: {
    SLSectionContent,
    SLSectionStrategy
  }
}
</script>

<style lang="scss" scoped>
.sections {
  display: grid;
  grid-template-columns: 470px 265px;
  column-gap: 40px;
}
</style>