import Vue from 'vue'
import VueRouter from 'vue-router'
import SLWidget from '@/views/SLWidget'
import NotFound from '../views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/sl/:pageUrl',
    name: 'SLWidget',
    component: SLWidget,
    meta: {widgetType: 'sl'}
  },

  {
    path: '*',
    name: 'notfound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
