<template>
  <div id="app" v-html="notFoundMessage"></div>
</template>

<script>
import DynamoDB from '@/models/DynamoDB'

export default {
  data() {
    return {
      notFoundMessage: ''
    }
  },

  async beforeRouteEnter (to, from, next) {
    next(vm => vm.getNotFoundMessage());
  },

  methods: {
    async getNotFoundMessage() { this.notFoundMessage = await DynamoDB.getNotFoundMessage(); }
  }
}
</script>

<style lang="scss">
</style>
