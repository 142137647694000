<template>
  <div :id="'section' + id" class="section" :class="[section.sectionType, {'is-ios': getIsIOS}]">
    <template v-if="section.sectionType != 'image'">
      <h3 v-if="section.title" class="title" v-html="section.title"></h3>
      <p v-if="section.subTitle" class="subtitle" v-html="section.subTitle"></p>
      <div v-if="section.title || section.subTitle" class="space"></div>
      <p v-if="section.content" class="content" :class="{'hide-play-cursor': getActiveStep == id}" v-html="section.content"></p>
    </template>
    <div v-else class="content-img">
      <img 
        :src="section.asset.imageUrl" 
        :data-1x="section.asset.imageUrl" 
        :data-2x="section.asset.retinaUrl"
        :alt="section.asset.altTextFr" 
        height="200" 
        width="210"
        draggable="false"
      >
      <div class="img-border"></div>
    </div>
    <SLButton v-if="this.section.showPlayIcon" :hide="getActiveStep == id" :top="actionTextLoc" @click.native="showStrategy()"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SLButton from '@/components/SLButton'

export default {
  props: {
    id: {
      type: Number,
      default: null
    },

    section: {
      type: Object,
      default: null
    }
  },

  components: { SLButton },

  data() { 
    return { 
      actionTextLoc: null,
      greenElement: null
    } 
  },

  computed: {
    ...mapGetters([
      'getActiveStep',
      'getIsIOS'
    ])
  },

  mounted() { 
    document.fonts.ready.then(this.setupPlayButtonLoc());
    this.greenElement = document.querySelector('#section' + this.id + ' d1');

    if (this.greenElement) 
      this.greenElement.addEventListener("click", this.showStrategy);
  },

  methods: {
    setupPlayButtonLoc() {
      setTimeout(_=> {
        try {
          let element = this.greenElement;

          if (this.section.showPlayIcon === "contentDynamic") {
            element = !element ? document.querySelector('#section' + this.id + ' d2'): element;
            this.actionTextLoc = (element.offsetTop - 1) - (this.getIsIOS ? 2: 0);
          }

          else if (this.section.showPlayIcon === "contentTop") {
            element = this.checkSectionType();
            this.actionTextLoc = (element.offsetTop + (this.getIsIOS ? 3: 5)) - (this.section.sectionType ===  'instruction' ? (this.getIsIOS ? 6: 5): 0);
          }

          else if (this.section.showPlayIcon === "contentMiddle") {
            element = this.checkSectionType();
            this.actionTextLoc = (element.offsetTop + (element.offsetHeight / 2)) - (25 / 2) + (this.checkSectionTypeImage() ? 10: 0 )
          }

          else if (this.section.showPlayIcon === "title") 
            this.actionTextLoc = document.querySelector('#section' + this.id + ' .title').offsetTop + 5;

        } catch (error) {
          this.actionTextLoc = 0;
        }
      }, 600)
    },

    checkSectionType() { return this.checkSectionTypeImage() ? document.querySelector('#section' + this.id + ' img'): document.querySelector('#section' + this.id + ' .content'); },

    checkSectionTypeImage() { return this.section.sectionType === 'image' },

    showStrategy() {
      let strategy = document.querySelector('#steps' + this.id);
      this.clearStrategyActive();
      strategy.classList.add('show');
      this.$store.commit('setActiveStep', this.id); 
    },

    clearStrategyActive() {
      let strategy = document.querySelectorAll('.steps-container');

      for (let i = 0; i < strategy.length; i++)
        strategy[i].classList.remove('show')

      this.$store.commit('setActiveStep', null);
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  position: relative;
  margin-bottom: 18px;

  &.instruction { 
    margin-bottom: 57px;
    font-size: 18px;

    .content { line-height: 24px; }
  }

  .space { margin-bottom: 19px; }

  .title { 
    font-size: 26px;
    font-weight: 900;
  }

  p { margin: 0; }

  .content-img {
    line-height: 0;
    margin-bottom: 7px;
    margin-top: 10px;
  }

  .title, .subtitle, .content { line-height: 35px; }
}

</style>