/**
*
* This plugin wraps the `axios` package.
* For the AJAX response, please refer to https://www.npmjs.com/package/axios#response-schema
*/
import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {'X-Api-Key': process.env.VUE_APP_API_KEY}
});

export default instance;