import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pages: [],
    sections: [],
    assets: [],
    themes: [],
    activeStep: null,
    widgetData: {},
    isMobile: false,
    isIpad: false,
    isIOS: false
  },
  mutations: {
    setPages(state, value) {
      state.pages = value;
    },

    setSections(state, value) {
      state.sections = value;
    },

    setAssets(state, value) {
      state.assets = value;
    },

    setThemes(state, value) {
      state.themes = value;
    },

    setActiveStep(state, data) {
      state.activeStep = data
    },

    setWidgetData(state, data) {
      state.widgetData = data
    },

    setIsMobile(state, value) {
      state.isMobile = value;
    },
  
    setIsIpad(state, value) {
      state.isIpad = value;
    },
  
    setIsIOS(state, value) {
      state.isIOS = value;
    }
  },
  getters: {
    getPages(state) {
      return state.pages;
    },

    getPageByUrl(state) {
      return url => state.pages.find(element => element.pageUrl === url && element.status === '0');
    },

    getSections(state) {
      return state.sections;
    },

    getSectionByID(state) {
      return id => state.sections.find(element => element.SK1 === id && element.status === '0');
    },

    getAssets(state) {
      return state.assets;
    },

    getAssetByID(state) {
      return id => state.assets.find(element => element.SK1 === id && element.status === '0');
    },

    getThemes(state) {
      return state.themes;
    },

    getThemeByID(state) {
      return id => state.themes.find(element => element.SK1 === id && element.status === '0');
    },

    getActiveStep(state) {
      return state.activeStep
    },

    getWidgetData(state) {
      return state.widgetData
    },

    getIsMobile(state) {
      return state.isMobile;
    },
  
    getIsIpad(state) {
      return state.isIpad;
    },
  
    getIsIOS(state) {
      return state.isIOS;
    }
  },
  actions: {
  },
  modules: {
  }
})
