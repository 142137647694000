
export default {
  clone(arg) { return JSON.parse(JSON.stringify(arg)); },

  push(arg, elements) {
    for (let element of elements)
      arg.push(element);
  },

  getError(err) {
    let message = (err.response && err.response.data.message) ? err.response.data.message : err.message;
    let error = new Error(message);
    console.error(error);
    return error;
  },
  
  initRetina() {
    var images = document.querySelectorAll("img[data-1x]");
    if (this.isRetina() == true) {
      Array.prototype.forEach.call(images, function(el, i) {
        var src = el.getAttribute("data-2x");
        el.setAttribute("src", src);
      });
    } else {
      Array.prototype.forEach.call(images, function(el, i) {
        var src = el.getAttribute("data-1x");
        el.setAttribute("src", src);
      });
    }
  },

  isRetina() {
    if (window.devicePixelRatio == 2) 
      return true;
    else 
      return false;
  },

  setProperties(arg) {
    let html = document.querySelector('html');

    for (let key in arg)
      html.style.setProperty(key, arg[key]);
  }
}