<template>
  <div class="widget">
    <template v-for="(section, index) in sections">
      <SLSections :key="'section-' + index" :index="index" :section="section"/>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SLSections from '@/components/SLSections'
import DynamoDB from '@/models/DynamoDB'
import Util from '@/helpers/Util';

export default {
  components: {
    SLSections
  },

  computed: {
    ...mapGetters(['getWidgetData']),

    sections() { return this.getWidgetData.sections },

    theme() { return this.getWidgetData.theme },

    pageTitle() { return this.getWidgetData.pageTitle }
  },

  async beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchData());
  },

  methods: {
    async fetchData() {
      try {
        let dynamoDB = new DynamoDB(this.$store);
        await dynamoDB.initialize(this.$route.meta.widgetType, this.$route.params.pageUrl)

        this.$store.commit('setWidgetData', dynamoDB.getData(this.$route.params.pageUrl));
        this.changePageTitle();
        this.changeFavicon();
        this.prepareCssVar();
      } catch (error) {
        this.$router.push({name: 'notfound'});
      }
    },

    changePageTitle() { document.title = this.pageTitle },

    changeFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = this.theme.favicon.imageUrl;
    },

    prepareCssVar() {
      Util.setProperties({
        '--d1-text-color': this.theme.d1Color,
        '--d2-text-color': this.theme.d2Color,
        '--d3-text-color': this.theme.d3Color,
        '--animation-delay': this.theme.animationDelay + 's',
        '--highlight-text-color': this.theme.textHighlightColor
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.widget { padding: 20px 0 20px 40px; }
</style>